import '@angular/localize/init';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { getApiServiceProviders } from '@ev-portals/dp/frontend/shared/api-client';
import { AuthInterceptor } from '@ev-portals/dp/frontend/shared/auth/data-access';
import { Environment } from '@ev-portals/dp/frontend/shared/util';
import { loadBasfAtoms } from '@ev-portals/ev/frontend/ui-library';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { ScriptInjector } from './app/util';
import { environment } from './environments/environment';

init();

async function init(): Promise<void> {
  ScriptInjector.injectTealiumScript();
  ScriptInjector.injectNoIndexMetaTag();

  // Going to be loaded asynchronously, to reduce the size of the main.js
  await loadBasfAtoms();

  if (environment.production) {
    enableProdMode();
  }

  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(BrowserAnimationsModule),
      importProvidersFrom(HttpClientModule),
      importProvidersFrom(BrowserAnimationsModule),
      provideRouter(
        appRoutes,
        withComponentInputBinding(),
        withPreloading(PreloadAllModules),
        // Note: doesn't work due to atom-shell, we fix this in NavigationService
        withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      ),
      {
        provide: LOCALE_ID,
        useValue: 'en-IN',
      },
      { provide: Environment, useValue: environment },
      ...getApiServiceProviders({
        useMockData: environment.apiMockData,
        apiBaseUrl: environment.apiBaseUrl,
      }),
      [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    ],
  }).catch(console.error);
}
